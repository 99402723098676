<template>
  <div class="Certificate">
    <Banner tit1="证书发放" tit2="Certificate issuance" url="3"></Banner>

    <div class="search">
      <h2>证书查询</h2>
      <div class="form">
        <el-form
          ref="form"
          :model="search"
          label-width="80px"
          label-position="top"
        >
          <el-form-item label="证书持有人姓名:">
            <el-input
              v-model="search.name"
              placeholder="请输入证书持有人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号或身份证号:">
            <el-input
              v-model="search.search"
              placeholder="请输入持有人手机号或身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item class="btn">
            <el-button type="primary" @click="searchCert">查询</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div v-show="notCertShow" style="margin-left:140px">
        <img class="no" src="../../assets/images/certs/no.png" alt srcset />
        <p class="part-cont ">未查询到证书</p>
      </div>
      <el-table v-show="tableShow" :data="tableData" border style="width: 95%">
        <el-table-column label="序号" width="80px">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="cert_name" label="证书名称">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 0">初级网培师证书</span>
            <span v-if="scope.row.type == 1">中级网培师证书</span>
          </template>
        </el-table-column>

        <el-table-column prop="pay_type" label="查看">
          <template slot-scope="scope">
            <el-button type="text" @click="certShow(scope.row.cert)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="certinfo">
        <h2>证书介绍</h2>
        <div class="certinfo_img">
          <img src="../../assets/images/home/certificate.jpg" alt="" />
        </div>
        <el-card class="box_card">
          <p>
            参与评测的人员，通过了“教学综合能力评测”、“网培政策规范评测”以及“教学数字化能力评测”三个模块的评测后，即可获得由中国商业联合会颁发的“网培师证书”，并可同时申请进入合作教培机构的教师储备人才库。申报高级网培师的人员，其申报资料经过认证中心的组织的专家评审后，将获得“高级网培师”证书。
          </p>
          <p>
            本项目并与联合会训研所UNITAR中国中心建立协作，网培师证书获得者可另外提出申请并支付一定评审费后，可获得由联合国训研所中国中心颁发的在线教育教师训练合格证书，并进入联合国训研所全球人才库。
          </p>
          <div class="certinfo_img">
            <img src="../../assets/images/certs/img2.png" alt="" />
          </div>
        </el-card>
      </div>
    </div>

    <div class="seecert">
      <h2>查看电子证书</h2>
      <div class="tip">
        网培师联盟与教育培训学习银行平台合作，实现证书上链。
      </div>
      <div class="see_img">
        <p>扫描小程序二维码查看</p>
        <img src="../../assets/images/certs/img3.png" alt="" preview />
      </div>
      <div class="see_footer"></div>
    </div>

    <div class="bottom">
      <div class="mechanism">
        <h2>认证机构</h2>
        <div class="txt">
          <p>
            网培师是在国内推进在线教育发展及人社部推出在线教育相关新职业岗位的背景下推出的职业岗位，经上海市培训协会、上海人才服务行业协会、上海现代服务业联合会互联网科创专委会共同批准发起，经中国商业联合会批准设立的水平评价类资格证书。
          </p>
          <p>
            网培师能力评测认证中心负责进行面向广大的在线教育培训从业人员提供网络培训能力的测评认证，项目采用网班教育的《网培师教育培训及评测标准》体系。项目平台并与上海市人才服务行业协会一千多家企业会员单位等头部教培机构建立人才库的合作，面向国内的在线教育培训工作者提供技能培训、评测认证、上岗就业等系列服务。
          </p>
        </div>
        <div class="me_img">
          <img src="../../assets/images/certs/mlogo.png" alt="" />
        </div>
      </div>

      <div class="txt_list">
        <h3>发证机构介绍：</h3>
        <p class="jgname">中国商业联合会</p>
        <p>
          中国商业联合会（英文：CHINA GENERAL CHAMBER OF
          COMMERCE，缩写CGCC）是1994年经民政部注册登记的具有社团法人资格的全国性行业组织。中国商业联合会前身是中华人民共和国商业部（后为国家国内贸易部），是中国商业流通领域的行业管理协会，原国家商业部、国家国内贸易部副部长何济海担任首任会长。
          本会的登记管理机关是民政部，党建领导机关是国务院国有资产监督管理委员会党委。
        </p>
        <p class="jgname">上海市培训协会</p>
        <p>
          上海市培训协会是由上海市开展培训领域相关服务的机构自愿组成，实行行业服务和自律管理的非营利性行业组织。协会由华东师范大学、上海开放大学、上海市师资培训中心、上海市教育评估协会、上海市民办教育协会、上海市青少年体育协会，以及上海精锐教育培训有限公司、上海昂立教育培训有限公司等多家单位共同发起。协会宗旨：通过规范开展活动，发挥桥梁作用，服务协调、引导促进、监督指导本市相关公办与民办机构规范发展，加强机构自律和行业自律、促进培训市场健康发展。
        </p>
      </div>
      <div class="txt_item">
        <h4>项目联合发起：</h4>
        <p>上海市培训协会</p>
        <p>上海人才服务行业协会</p>
        <p>上海现代服务业联合会互联网科创服务专委会</p>
        <p>上海网班教育科技股份有限公司</p>
      </div>
      <div class="txt_item">
        <h4>全国项目设立：</h4>
        <p>中国商业联合会</p>
      </div>
      <div class="txt_item">
        <h4>项目《网培师培训及评测认证标准》制定：</h4>
        <p>网培师评测标准专家组</p>
        <p>上海人才服务行业协会</p>
        <p>上海现代服务业联合会互联网科创服务专委会</p>
      </div>
      <div class="txt_item">
        <h4>项目执行：</h4>
        <p>网班培训师认证服务中心</p>
      </div>
      <div class="txt_item">
        <h4>技术支持:</h4>
        <p>上海网班教育科技股份有限公司</p>
      </div>
      <div class="txt_item">
        <h4>项目合作机构：</h4>
        <p>
          上海市信息化青年人才协会、上海市信息化企业家协会、上海信息服务行业协会，以及国内各大主流教育培训机构。
        </p>
      </div>
      <div class="txt_item">
        <h4>特别合作机构：</h4>
        <p>联合国训练研究所中国中心</p>
      </div>
    </div>
    <el-dialog :visible.sync="certImgShow" width="90%">
      <img :src="url + imgUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import Banner from './layout/Banner'
export default {
  data() {
    return {
      search: {},
      tableShow: false,
      notCertShow: false,
      tableData: [],
      certImgShow: false,
      url: 'http://api.wangpeishi.org.cn/',
      imgUrl: '',
    }
  },
  components: {
    Banner,
  },
  methods: {
    searchCert() {
      let _this = this
      if (_this.search.name == '') {
        _this.$message.error('请输入证书持有人姓名')
        return false
      }
      if (_this.search.search == '') {
        _this.$message.error('请输入持有人手机号或身份证号')
        return false
      }
      _this.$http
        .post(process.env.VUE_APP_URL + 'cert', _this.search)
        .then(data => {
          if (data.data.code == 200) {
            if (data.data.data.length == 0) {
              _this.notCertShow = true
              _this.tableShow = false
            } else {
              _this.tableData = data.data.data
              _this.tableShow = true
              _this.notCertShow = false
            }
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    certShow(row) {
      this.certImgShow = true
      this.imgUrl = row
    },
  },
}
</script>
<style lang="less" scoped>
h2 {
  text-align: center;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000005;
  line-height: 24px;
}
.search {
  width: 100%;
  padding: 20px 0px;
  .form {
    max-width: 85vw;
    margin: 20px auto;
  }
  /deep/.btn .el-form-item__content {
    margin-left: 0px !important;
    text-align: center;
    .el-button {
      background: #960f23;
      border-radius: 4px;
      border: none;
      width: 145px;
    }
  }
  /deep/.el-form-item__label {
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}
.certinfo {
  width: 100%;
  padding-top: 20px;
  .certinfo_img {
    margin: 20px auto;
    width: 50vw;
    img {
      width: 100%;
      position: relative;
      z-index: 60;
    }
  }
  .box_card {
    width: 80vw;
    margin: 0px auto;
    padding-top: 40px;
    position: relative;
    top: -65px;
    p {
      margin: 0px 0px 25px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7c8087;
      line-height: 20px;
    }
  }
}
.seecert {
  width: 100%;
  padding: 20px 0px;
  .tip {
    width: 69vw;
    margin: 20px auto;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7c8087;
    line-height: 20px;
  }
  .see_img {
    width: 60vw;
    margin: 0px auto;
    p {
      text-align: center;
      margin-bottom: 10px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7c8087;
    }
    img {
      width: 100%;
    }
  }
  .see_footer {
    width: 100%;
    height: 180px;
    background-color: #f8f1eb;
    position: relative;
    top: -184px;
    z-index: -1;
  }
}
.bottom {
  position: relative;
  top: -100px;
}
.mechanism {
  h2 {
    margin: -50px 0px 20px;
  }
  .txt {
    max-width: 80vw;
    margin: 0px auto;
    p {
      margin-bottom: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
  .me_img {
    max-width: 85vw;
    margin: 0px auto;
    img {
      width: 100%;
    }
  }
}
.txt_list {
  width: 80vw;
  margin: 0px auto;
  h3 {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-top: 20px;
  }
  p {
    margin-top: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .jgname {
    color: #000000;
    font-weight: bold;
    font-size: 15px;
  }
}
.txt_item {
  width: 80vw;
  margin: 20px auto 10px;
  h4 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  p {
    margin-top: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}
.el-table {
  margin: 0 10px;
}
.no {
  width: 100px;
  padding-left: 12px;
}
.part-cont {
  color: #333;
  font-size: 18px;
  padding-left: 8px;
}
</style>
